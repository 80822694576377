.dark {
    background-color: #333;
    color: #fff;
  }
  .light {
    background-color: #fff;
    color: #333;
  }
  
  .roll-effect {
    animation: rollEffect 1800 ms ease-in-out;
    display: flex;
    align-items: center;
    height: 34px; /* Set the desired height for each text */
  }

  

  .textWrapper {
    
    padding: 10px;
    height: 100px;
    width: 580px;
  }
  
  